
import { Vue } from "vue-class-component";

export default class SignOut extends Vue {
	sec = 3
	
	
	created() {
		
		const interv = setInterval(() => {
			this.sec--
			if ( this.sec === 0 ) {
				clearInterval(interv)
				this.$router.push({ name: "SignIn"})
			}
		}, 1000)
	}
}
